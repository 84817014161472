// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-c-0-llege-js": () => import("./../../../src/pages/c0llege.js" /* webpackChunkName: "component---src-pages-c-0-llege-js" */),
  "component---src-pages-develop-js": () => import("./../../../src/pages/develop.js" /* webpackChunkName: "component---src-pages-develop-js" */),
  "component---src-pages-ecos-js": () => import("./../../../src/pages/ecos.js" /* webpackChunkName: "component---src-pages-ecos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-template-activity-category-js": () => import("./../../../src/template/activityCategory.js" /* webpackChunkName: "component---src-template-activity-category-js" */),
  "component---src-template-announcement-category-js": () => import("./../../../src/template/announcementCategory.js" /* webpackChunkName: "component---src-template-announcement-category-js" */),
  "component---src-template-article-js": () => import("./../../../src/template/article.js" /* webpackChunkName: "component---src-template-article-js" */),
  "component---src-template-defi-category-js": () => import("./../../../src/template/defiCategory.js" /* webpackChunkName: "component---src-template-defi-category-js" */),
  "component---src-template-ecosystem-category-js": () => import("./../../../src/template/ecosystemCategory.js" /* webpackChunkName: "component---src-template-ecosystem-category-js" */),
  "component---src-template-ether-1-category-js": () => import("./../../../src/template/ether1Category.js" /* webpackChunkName: "component---src-template-ether-1-category-js" */),
  "component---src-template-ether-2-category-js": () => import("./../../../src/template/ether2Category.js" /* webpackChunkName: "component---src-template-ether-2-category-js" */),
  "component---src-template-layer-2-category-js": () => import("./../../../src/template/layer2Category.js" /* webpackChunkName: "component---src-template-layer-2-category-js" */),
  "component---src-template-staking-category-js": () => import("./../../../src/template/stakingCategory.js" /* webpackChunkName: "component---src-template-staking-category-js" */),
  "component---src-template-technology-category-js": () => import("./../../../src/template/technologyCategory.js" /* webpackChunkName: "component---src-template-technology-category-js" */),
  "component---src-template-thinking-category-js": () => import("./../../../src/template/thinkingCategory.js" /* webpackChunkName: "component---src-template-thinking-category-js" */)
}

